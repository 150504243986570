// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getAnalytics } from "@firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAW1JA_79lfv2eIvNWvdQoxhTu4FoBgwsM",
  authDomain: "otp-alapok-768eb.firebaseapp.com",
  projectId: "otp-alapok-768eb",
  storageBucket: "otp-alapok-768eb.appspot.com",
  messagingSenderId: "920620247013",
  appId: "1:920620247013:web:5666ec3203ff927283f10c",
  measurementId: "G-Q92QNDJZZT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app)

import { validate } from 'class-validator'
import { CreateMessageDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { CREATE_MESSAGE_ACTIONS } from './message.constants'
import { plainToClass } from 'class-transformer'

export const sendMessage = async () => {

    store.dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_STARTED })

    const { name, email, phone, message, type } = store.getState().contact.form

    const params = {
        type: type.value,
        name: name.value,
        email: email.value,
        phoneNumber: phone.value,
        message: message.value,
    }

    const promise = createCall('microsite/message', BASE_METHODS.POST, JSON.stringify(params))
    promise.then(async response => {

        let responseBody: CreateMessageDTO

        try {
            responseBody = plainToClass( CreateMessageDTO, JSON.parse( await response.text() ) )

        } catch (error) {
            //console.log(error)
            store.dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_INVALID_JSON })
            return
        }

        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch(store.dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_INVALID_RESPONSE }))
            return
        }

        //console.log(responseBody)

        store.dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_OK, message: responseBody.message})
       
    })

    promise.catch(error => {
        //console.log(error)
        store.dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_ERROR })
    })
}



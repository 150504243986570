export const PortfolioManagement = [
    {
        name: 'managers_halas_zoltan_title',
        description: 'managers_halas_zoltan_position_title',
        postDesctription: 'managers_halas_zoltan_description',
        imageUrl: 'image/halas_zoltan.png',
    },
    {
        name: 'managers_czako_agnes_title',
        description: 'managers_czako_agnes_position_title',
        postDesctription: 'managers_czako_agnes_description',
        imageUrl: 'image/czako_agnes.png',
    },
    {
        name: 'managers_gajdacs_attila_title',
        description: 'managers_gajdacs_attila_position_title',
        postDesctription: 'managers_gajdacs_attila_description',
        imageUrl: 'image/gajdacs_attila.png',
    },
    {
        name: 'managers_heinz_frigyes_title',
        description: 'managers_heinz_frigyes_position_title',
        postDesctription: 'managers_heinz_frigyes_description',
        imageUrl: 'image/heinz_frigyes.png',
    },
]

import { GET_FUND_ACTIONS } from '../action/fund/get-fund.constants'
import { createFormattedDateString } from '../helper/date.helper'
import { FundState } from '../state'

const initialFundState: FundState = {
    euroSeries: [], 
    euroOptions: [], 

    ytdValue: "", 
    onYearValue: "", 
    fromStartValue: "", 

    aletMessage: '' 
}

export const fundReducer = (state: FundState = initialFundState, action: any): FundState => {
    switch (action.type) {
        case GET_FUND_ACTIONS.GET_FUND_STARTED:
            return { ...state }
        case GET_FUND_ACTIONS.GET_FUND_INVALID_JSON:
            return { ...state }
        case GET_FUND_ACTIONS.GET_FUND_INVALID_RESPONSE:
            return { ...state, aletMessage: `Váratlan hiba történt!\nA hiba tényét rögzítettük és továbbítottuk a fejlesztőknek!` }
        case GET_FUND_ACTIONS.GET_FUND_OK:
            var euroOptions:string[] = []

            action.euroOptions.map((option:any) => {
                euroOptions.push(createFormattedDateString(option))
            } )   

            return { ...state, euroOptions: euroOptions, euroSeries: action.euroSeries, ytdValue: action.ytdValue, onYearValue: action.onYearValue, fromStartValue: action.fromStartValue }
        case GET_FUND_ACTIONS.GET_FUND_ERROR:
            return { ...state, aletMessage: action.message }
        default:
            return state
    }
}

export const CREATE_MESSAGE_ACTIONS = {
    CREATE_MESSAGE_ACTION_STARTED: 'CREATE_MESSAGE_ACTION_STARTED',
    CREATE_MESSAGE_ACTION_OK: 'CREATE_MESSAGE_ACTION_OK',
    CREATE_MESSAGE_ACTION_ERROR: 'CREATE_MESSAGE_ACTION_ERROR',
    CREATE_MESSAGE_ACTION_INVALID_RESPONSE: 'CREATE_MESSAGE_ACTION_INVALID_RESPONSE',
    CREATE_MESSAGE_ACTION_INVALID_JSON: 'CREATE_MESSAGE_ACTION_INVALID_JSON',
    CREATE_MESSAGE_ACTION_CLEAR: 'CREATE_MESSAGE_ACTION_CLEAR',

    CREATE_MESSAGE_CHANGE_NAME: 'CONTACT_CHANGE_NAME',
    CREATE_MESSAGE_CHANGE_NAME_ERROR: 'CONTACT_CHANGE_NAME_ERROR',

    CREATE_MESSAGE_CHANGE_EMAIL: 'CONTACT_CHANGE_EMAIL',
    CREATE_MESSAGE_CHANGE_EMAIL_ERROR: 'CONTACT_CHANGE_EMAIL_ERROR',

    CREATE_MESSAGE_CHANGE_PHONE: 'CONTACT_CHANGE_PHONE',
    CREATE_MESSAGE_CHANGE_PHONE_ERROR: 'CONTACT_CHANGE_PHONE_ERROR',

    CREATE_MESSAGE_CHANGE_MESSAGE: 'CONTACT_CHANGE_MESSAGE',
    CREATE_MESSAGE_CHANGE_MESSAGE_ERROR: 'CONTACT_CHANGE_MESSAGE_ERROR',

    CREATE_MESSAGE_CHANGE_TYPE: 'CONTACT_CHANGE_TYPE',
    CREATE_MESSAGE_CHANGE_TYPE_ERROR: 'CONTACT_CHANGE_TYPE_ERROR',

}

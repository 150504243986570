export const FooterCardContent = [
    {
        id: 1,
        title: 'founds_page_landing_page_first_modal_title',
        description: 'founds_page_landing_page_first_modal_description',
        path: '/alapok/konzervativ',
        buttonText: 'founds_page_landing_page_modal_footer_button_title',
    },
    {
        id: 2,
        title: 'founds_page_landing_page_second_modal_title',
        description: 'founds_page_landing_page_second_modal_description',
        path: '/alapok/kiegyensulyozott',
        buttonText: 'founds_page_landing_page_modal_footer_button_title',
    },
    {
        id: 3,
        title: 'founds_page_landing_page_third_modal_title',
        description: 'founds_page_landing_page_third_modal_description',
        path: '/alapok/dinamikus',
        buttonText: 'founds_page_landing_page_modal_footer_button_title',
    },
]

export const ReferenceCards = [
    {
        imageUrl: 'image/otp_fooldal_ismerj_meg_1_logo.svg',
        label: 'company_professional_experience_card_title',
    },
    {
        imageUrl: 'image/otp_fooldal_ismerj_meg_2_logo.svg',
        label: 'company_managed_assets_card_title',
    },
    {
        imageUrl: 'image/otp_fooldal_ismerj_meg_3_logo.svg',
        label: 'company_mutual_fund_card_title',
    },
    {
        imageUrl: 'image/company_reference_60_logo.svg',
        label: 'company_employee_card_title',
    },
]

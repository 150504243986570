export const Milestones = [
    {
        year: '2022',
        text: 'company_milestone_first_title',
    },
    {
        year: '2019',
        text: 'company_milestone_second_title',
    },
    {
        year: '2018',
        text: 'company_milestone_third_title',
    },
    {
        year: '2006',
        text: 'company_milestone_fourth_title',
    },
    {
        year: '2004',
        text: 'company_milestone_fifth_title',
    },
    {
        year: '2002',
        text: 'company_milestone_sixth_title',
    },
    {
        year: '2000',
        text: 'company_milestone_seventh_title',
    },
    {
        year: '1996',
        text: 'company_milestone_eighth_title',
    },
    {
        year: '1993',
        text: 'company_milestone_nineth_title',
        last: true,
    },
]

export const CompanyManagement = [
    {
        name: 'company_management_fisrt_card_title',
        description: 'company_management_first_card_description',
        postDesctription: 'company_management_first_card_post_description',
        imageUrl: 'image/banfi_attila_company_management_card_picture.png',
    },
    {
        name: 'company_management_second_card_title',
        description: 'company_management_second_card_description',
        postDesctription: 'company_management_second_card_post_description',
        imageUrl: 'image/eder_gyorgy_company_management_card_picture.png',
    },
    {
        name: 'company_management_third_card_title',
        description: 'company_management_third_card_description',
        postDesctription: 'company_management_third_card_post_description',
        imageUrl: 'image/turner_tibor_company_management_card_picture.png',
    },
    {
        name: 'company_management_fourth_card_title',
        description: 'company_management_fourth_card_description',
        postDesctription: 'company_management_fourth_card_post_description',
        imageUrl: 'image/honics_istvan_company_management_card_picture.png',
    },
]

import { MouseEventHandler } from 'react'
import './message.modal.scss'

interface MessageModalProps {
    title: string
    message: string
    onCloseClick?: MouseEventHandler
}

export const MessageModal = (props: MessageModalProps) => {
    return (
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <div className="modal__title"> {props.title} </div>
                    <button className="close-button" onClick={props.onCloseClick}>
                        <img alt="Bezárás gomb" src="/image/ic_close.svg" />
                    </button>
                </div>

                <div className="">
                    <h3 className="modal__message">{props.message}</h3>
                </div>
            </div>
        </div>
    )
}

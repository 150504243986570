import { useAppTranslation } from '../../hook'
import './impressum.page.scss'

export const Impressum = () => {
    const { t } = useAppTranslation()
    return (
        <main className="page page-impressum">
            <div className="main-div">
                <div className='content-card'>
                    <div className='text-container'>
                        <ul>
                            <li>{t("impressum_discription_text_host")}</li>
                            <li>{t("impressum_discription_text_postal_address")}</li>
                            <li>{t("impressum_discription_text_phone_number")}</li>
                            <li>{t("impressum_discription_text_fax")}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    )
}

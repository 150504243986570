import { CREATE_MESSAGE_ACTIONS } from '../action/message/message.constants'
import { ContactState } from '../state'

const initialContactState: ContactState = {
    form: {
        name: {
            error: '',
            value: '',
            required: true,
        },
        email: {
            error: '',
            value: '',
            required: true,
        },
        phone: {
            error: '',
            value: '',
            required: true,
        },
        message: {
            error: '',
            value: '',
            required: true,
        },
        type: {
            error: '',
            value: 0,
        },
    },
    message: null,
    aletMessage: '',
}

export const contactReducer = (state: ContactState = initialContactState, action: any): ContactState => {
    switch (action.type) {
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_NAME:
            return { ...state, form: { ...state.form, name: { ...state.form.name, value: action.name, error: '' } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_NAME_ERROR:
            return { ...state, form: { ...state.form, name: { ...state.form.name, error: action.error } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_EMAIL:
            return { ...state, form: { ...state.form, email: { ...state.form.email, value: action.email, error: '' } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_EMAIL_ERROR:
            return { ...state, form: { ...state.form, email: { ...state.form.email, error: action.error } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_PHONE:
            return { ...state, form: { ...state.form, phone: { ...state.form.phone, value: action.phone, error: '' } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_PHONE_ERROR:
            return { ...state, form: { ...state.form, phone: { ...state.form.phone, error: action.error  } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_MESSAGE:
            return { ...state, form: { ...state.form, message: { ...state.form.message, value: action.message, error: '' } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_MESSAGE_ERROR:
            return { ...state, form: { ...state.form, message: { ...state.form.message, error: action.error  } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_TYPE:
            return { ...state, form: { ...state.form, type: { ...state.form.type, value: action.typeId, error: '' } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_TYPE_ERROR:
            return { ...state, form: { ...state.form, type: { ...state.form.type,  error: action.error } } }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_STARTED:
            return { ...state }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_INVALID_JSON:
            return { ...state }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_INVALID_RESPONSE:
            return { ...state, aletMessage: `Váratlan hiba történt!\nA hiba tényét rögzítettük és továbbítottuk a fejlesztőknek!` }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_OK:
            return { ...state, message: action.message }
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_CLEAR:
            return initialContactState
        case CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_ERROR:
            return { ...state, aletMessage: action.message }
        default:
            return state
    }
}

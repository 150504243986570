import { FooterCard, Infomation, TabView } from '../../component'
import { TabViewPage } from '../../component/tab-view/tab-view-page'
import { useAppState, useAppTranslation, useWindowDimensions } from '../../hook'
import { TabAbout } from './tab-about'
import { TabDocs } from './tab-docs'
import { TabFounds } from './tab-founds'
import { TabPresent } from './tab-present'
import { Menu } from '../../enum'
import './founds.detail.page.scss'

interface FoundsDetailProps {
    type: Menu
}

export const FoundsDetail = (props: FoundsDetailProps) => {
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()

    const [state, setState] = useAppState({
        firstTabVisible: false,
        secondTabVisible: false,
        thirdTabVisible: false,
        forthTabVisible: false,
    })

    const handleFirstTabVisible = () => {
        setState((prevState) => ({ ...prevState, firstTabVisible: !state.firstTabVisible }))
    }

    const handleSecondTabVisible = () => {
        setState((prevState) => ({ ...prevState, secondTabVisible: !state.secondTabVisible }))
    }

    const handleThirdTabVisible = () => {
        setState((prevState) => ({ ...prevState, thirdTabVisible: !state.thirdTabVisible }))
    }

    const handleForthTabVisible = () => {
        setState((prevState) => ({ ...prevState, forthTabVisible: !state.forthTabVisible }))
    }

    return (
        <main className="page page-founds-conservative">
            <div className="main-div">
                <p className="top-text">{t('founds_detail_top_description')}</p>

                <div className="tab-div">
                    {width <= 576 ? (
                        <div className="tab-items-container">
                            <div className="tab-item-container">
                                <div className="tab-item" onClick={handleFirstTabVisible}>
                                    <p className="tab-item-title">{t('founds_detail_tab_first_title')}</p>

                                    <button className="arrow-button">
                                        <img className={`arrow-icon ${state.firstTabVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down_green.svg" />
                                    </button>
                                </div>
                                <div style={{ display: state.firstTabVisible ? 'block' : 'none' }}>
                                    <TabPresent type={props.type} />
                                </div>
                            </div>

                            <div className="tab-item-container">
                                <div className="tab-item" onClick={handleSecondTabVisible}>
                                    <p className="tab-item-title">{t('founds_detail_tab_second_title')}</p>

                                    <button className="arrow-button">
                                        <img className={`arrow-icon ${state.secondTabVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down_green.svg" />
                                    </button>
                                </div>
                                <div style={{ display: state.secondTabVisible ? 'block' : 'none' }}>
                                    <TabFounds type={props.type} />
                                </div>
                            </div>

                            <div className="tab-item-container">
                                <div className="tab-item" onClick={handleThirdTabVisible}>
                                    <p className="tab-item-title">{t('founds_detail_tab_third_title')}</p>

                                    <button className="arrow-button">
                                        <img className={`arrow-icon ${state.thirdTabVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down_green.svg" />
                                    </button>
                                </div>
                                <div style={{ display: state.thirdTabVisible ? 'block' : 'none' }}>
                                    <TabAbout />
                                </div>
                            </div>

                            <div className="tab-item-container">
                                <div className="tab-item" onClick={handleForthTabVisible}>
                                    <p className="tab-item-title">{t('founds_detail_tab_fourth_title')}</p>

                                    <button className="arrow-button">
                                        <img className={`arrow-icon ${state.forthTabVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down_green.svg" />
                                    </button>
                                </div>
                                <div style={{ display: state.forthTabVisible ? 'block' : 'none' }}>
                                    <TabDocs type={props.type} />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <TabView tabs={{ present: t('founds_detail_tab_first_title'), founds: t('founds_detail_tab_second_title'), about: t('founds_detail_tab_third_title'), docs: t('founds_detail_tab_fourth_title') }}>
                            <TabViewPage key="present">
                                <TabPresent type={props.type} />
                            </TabViewPage>

                            <TabViewPage key="founds">
                                <TabFounds type={props.type} />
                            </TabViewPage>

                            <TabViewPage key="about">
                                <TabAbout />
                            </TabViewPage>

                            <TabViewPage key="docs">
                                <TabDocs type={props.type} />
                            </TabViewPage>
                        </TabView>
                    )}
                </div>

                <Infomation pathPrefix="../" />

                <FooterCard />

                <div className="bottom-div">
                    <div className={`bottom-div-bg ${window?.visualViewport!!.width < width ? `` : `full-width`}`} />

                    <div className="bottom-text" dangerouslySetInnerHTML={{ __html: t('founds_detail_bottom_description') }} />
                </div>
            </div>
        </main>
    )
}

import { Card, FooterCard, Infomation } from '../../component'
import { useAppDispatch, useAppTranslation, useWindowDimensions } from '../../hook'
import { cards } from '../founds/constant'
import { Link } from 'react-router-dom'
import { Menu } from '../../enum'
import { useEffect, useRef } from 'react'
import createPanZoom from 'panzoom'
import handleSubmit from '../../firebase_setup/handlesubmit'
import { analytics } from '../../firebase_setup/firebase'
import { logEvent } from '@firebase/analytics'
import './home.page.scss'

interface HomeProps {}

export const Home = (props: HomeProps) => {
    const dispatch = useAppDispatch()
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()

    const onItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    const dataRef = useRef<any>(null)

    const submitHandler = (e: Event) => {
        e.preventDefault()
        handleSubmit(dataRef.current.value)
        dataRef.current.value = ''
    }

    useEffect(() => {
        if (width <= 576) {
            var element = document.getElementById('map')
            createPanZoom(element!, {
                maxZoom: 3,
                minZoom: 1,
                bounds: true,
                boundsPadding: 1,
            })
        }
    }, [])

    return (
        <main className="page page-home">
            <div className="main-div">
                <div className="top-container">
                    {width <= 576 ? (
                        <FooterCard />
                    ) : (
                        <>
                            {cards.map((card, index) => (
                                <Card key={index} title={t(card.title)} description={''} buttonTitle={t(card.buttonTitle)} imageUrl={card.imageUrl} alt={card.alt} type={card.type} fixedHeight={false} path={card.path} />
                            ))}
                        </>
                    )}
                </div>

                <div className="middle-container">
                    <div className={`middle-container-bg ${window?.visualViewport!!.width < width ? `` : `full-width`}`} />

                    <div className="middle-container-inner">
                        <h1 className="title-text-h1">{t('home_middle_section_title')}</h1>

                        <div className="about-container">
                            <div className="item">
                                <img className="icon" src="image/otp_fooldal_ismerj_meg_1_logo.svg" />

                                <div className="label" dangerouslySetInnerHTML={{ __html: t('home_middle_section_first_card_title') }} />
                            </div>
                            <div className="item">
                                <img className="icon" src="image/otp_fooldal_ismerj_meg_2_logo.svg" />

                                <div className="label" dangerouslySetInnerHTML={{ __html: t('home_middle_section_second_card_title') }} />
                            </div>
                            <div className="item">
                                <img className="icon" src="image/otp_fooldal_ismerj_meg_3_logo.svg" />

                                <div className="label" dangerouslySetInnerHTML={{ __html: t('home_middle_section_third_card_title') }} />
                            </div>
                        </div>

                        <p className="description">{t('home_middle_section_description')}</p>
                        <Link
                            className="middle-container-button-link"
                            to={'/cegunk'}
                            onClick={() => {
                                window.scrollTo({ top: 0, left: 0 })
                                onItemClick(Menu.Company)
                            }}
                        >
                            <button className="button">{t('home_middle_section_button')}</button>
                        </Link>
                    </div>
                </div>

                <div className="bottom-container">
                    <h1 className="title-text-h1">{t('home_bottom_section_title')}</h1>

                    <div className="map-container">
                        <img id={'map'} alt="Térkép" className="map" src="/image/home_map.svg" />
                    </div>
                    <div className="type-container">
                        <div className="type-inner-container">
                            <div className="left-box" />

                            <p className="label">{t('home_bottom_section_left_title')}</p>
                        </div>
                        <div className="type-inner-container">
                            <div className="right-box" />

                            <p className="label">{t('home_bottom_section_right_title')}</p>
                        </div>
                    </div>
                </div>

                <Infomation />

                <FooterCard />

                {/* firebase test */}
                {/* <form onSubmit={() => submitHandler}>
                    <input type="text" ref={dataRef} />
                    <button
                        type="submit"
                        onClick={() => {
                            logEvent(analytics, 'test_analytics_event')
                        }}
                    >
                        Save
                    </button>
                </form> */}
            </div>
        </main>
    )
}

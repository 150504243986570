import { useAppTranslation } from '../../../hook'
import './tab.about.scss'

interface TabAboutProps {}

export const TabAbout = (props: TabAboutProps) => {
    const { t } = useAppTranslation()

    return (
        <main className="tab-about">
            <h2 className="title-text-h2">{t('founds_conservative_about_first_title')}</h2>

            <div className="desc-text" dangerouslySetInnerHTML={{ __html: t('founds_conservative_about_first_description') }} />

            <h2 className="title-text-h2">{t('founds_conservative_about_second_title')}</h2>

            <p className="desc-text">{t('founds_conservative_about_second_description')}</p>

            <h2 className="title-text-h2">{t('founds_conservative_about_third_title')}</h2>

            <p className="desc-text">{t('founds_conservative_about_third_description')}</p>

            <h2 className="title-text-h2">{t('founds_conservative_about_fourth_title')}</h2>

            <div className="desc-text" dangerouslySetInnerHTML={{ __html: t('founds_conservative_about_fourth_description') }} />
        </main>
    )
}

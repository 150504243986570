import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Document } from '../entity'

export class GetDocumentsDTO {
    @Expose()
    @ValidateNested()
    @Type(() => Document)
    documents!: Document[]
}

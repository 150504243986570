import { ChangeEvent } from 'react'
import './text.field.scss'

interface TextFieldProps {
    field: {
        disabled?: boolean
        error: string
        name?: string
        required?: boolean
        value: string
    }
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    maxlength?: number
    disabled?: boolean
    type?: string
}

export const TextField = (props: TextFieldProps) => {
    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault()
        const text = event.clipboardData.getData('text/plain')
        const cleanText = text.replace(/<[^>]+>/g, '')
        document.execCommand('insertText', false, cleanText)
    }

    return (
        <div className={`text-field`}>
            <div className={`field${props.field?.error.length > 0 ? ` field--error` : ``}`}>
                <input onChange={props.onChange} disabled={props.disabled === true || props.field?.disabled === true} id={props.field?.name} name={props.field?.name} placeholder={props.placeholder} required={props.field?.required === true} type={props.type != null ? props.type : 'text'} maxLength={props.maxlength} onPaste={handlePaste} value={props.field?.value} />
            </div>

            <div className={`error-field${props.field?.error.length > 0 ? `` : ` hidden`}`}>
                <img alt="" className="error-bubble" src="/image/ic_bubble_indicator_error.svg" />
                <p className="error-message">{props.field?.error}</p>
            </div>
        </div>
    )
}

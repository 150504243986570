import { validate } from 'class-validator'
import { GetFundDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { plainToClass } from 'class-transformer'
import { GET_FUND_ACTIONS } from './get-fund.constants'

export const getFund = async (id: number) => {

    store.dispatch({ type: GET_FUND_ACTIONS.GET_FUND_STARTED })

    const promise = createCall(`microsite/funds/${ id }`, BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetFundDTO

        try {
            responseBody = plainToClass( GetFundDTO, JSON.parse( await response.text() ) )
        } catch (error) {
            store.dispatch({ type: GET_FUND_ACTIONS.GET_FUND_INVALID_JSON })
            return
        }

        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch(store.dispatch({ type: GET_FUND_ACTIONS.GET_FUND_INVALID_RESPONSE }))
            return
        }

        store.dispatch({ type: GET_FUND_ACTIONS.GET_FUND_OK, euroOptions: responseBody.euroOptions, euroSeries: responseBody.euroSeries, ytdValue: responseBody.ytdValue, onYearValue: responseBody.onYearValue, fromStartValue: responseBody.fromStartValue})
    })

    promise.catch(error => {
        store.dispatch({ type: GET_FUND_ACTIONS.GET_FUND_ERROR })
    })
}


import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Menu } from '../../../enum'
import './floating-menu.scss'

interface FloatingMenuProps {
    open: boolean
    menu: number
    onMenuItemClick: (id: number) => void
}

export const FloatingMenu = (props: FloatingMenuProps) => {
    const { t, i18n } = useTranslation()

    return (
        <div className={`floating-menu ${props.open ? `` : `hidden`} ${i18n.language.includes('bg') ? `wide` : ``}`}>
            <img alt="" className="bubble" src="/image/ic_bubble_indicator.svg" />

            <ul className="floating-menu-bar">
                <li className="floating-menu-bar-item">
                    <NavLink className={`floating-menu-bar-item-title  ${Menu.FoundsConservative === props.menu ? `active` : ``}`} to={'alapok/konzervativ'} onClick={() => props.onMenuItemClick(Menu.FoundsConservative)}>
                        {t('founds_page_landing_page_first_card_header_title')}
                    </NavLink>
                </li>

                <li className="floating-menu-bar-item">
                    <NavLink className={`floating-menu-bar-item-title  ${Menu.FoundsBalanced === props.menu ? `active` : ``}`} to={'alapok/kiegyensulyozott'} onClick={() => props.onMenuItemClick(Menu.FoundsBalanced)}>
                        {t('founds_page_landing_page_second_card_header_title')}
                    </NavLink>
                </li>

                <li className="floating-menu-bar-item">
                    <Link className={`floating-menu-bar-item-title  ${Menu.FoundsDynamic === props.menu ? `active` : ``}`} to={'alapok/dinamikus'} onClick={() => props.onMenuItemClick(Menu.FoundsDynamic)}>
                        {t('founds_page_landing_page_third_card_header_title')}
                    </Link>
                </li>
            </ul>
        </div>
    )
}

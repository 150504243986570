import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { ExchangeRate } from '../entity'

export class GetExchangeRatesDTO {

    @Expose()
    @ValidateNested()
    @Type( () => ExchangeRate )
    exchangeRates!: ExchangeRate[]

}


import { Link } from 'react-router-dom'
import './found.card.scss'

interface FoundCardProps {
    key: number
    title: string
    buttonTitle: string
    url: string
}

export const FoundCard = (props: FoundCardProps) => {
    return (
        <div className="found-card" key={props.key}>
            <h3 className="title-text-h3">{props.title}</h3>

            <Link className="middle-container-button-link" target="_blank" rel="noopener noreferrer" to={props.url}>
                <button className="button">{props.buttonTitle}</button>
            </Link>
        </div>
    )
}

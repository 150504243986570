import { Link } from 'react-router-dom'
import { FooterCard, Infomation, MobileModal, ViewPager } from '../../component'
import { useAppDispatch, useAppState, useAppTranslation, useWindowDimensions } from '../../hook'
import { CompanyManagement, ManagementCard, ManagementModal, Milestones, ReferenceCards } from './constants'
import { Menu } from '../../enum'
import './company.page.scss'

export const Company = () => {
    const { t } = useAppTranslation()
    const dispatch = useAppDispatch()
    const { width } = useWindowDimensions()
    const [state, setState] = useAppState({
        modalVisible: false,
        managerId: NaN,
    })

    const handleModalVisible = (id: number) => {
        setState((prevState) => ({ ...prevState, modalVisible: !state.modalVisible }))
        if (id) {
            setState((prevState) => ({ ...prevState, managerId: id }))
        }
    }

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    const getReferenceCards = () => {
        const cards: React.ReactElement[] = []

        ReferenceCards.map((card, index) =>
            cards.push(
                <div className="item" key={index}>
                    <img className="icon" src={card.imageUrl} />

                    <div className="label" dangerouslySetInnerHTML={{ __html: t(card.label) }} />
                </div>
            )
        )
        return cards
    }

    const getMilestones = () => {
        const cards: React.ReactElement[] = []

        Milestones.map((card, index) => {
            cards.push(
                <div key={index}>
                    <div className="milestone-container">
                        <div className="year-container">{card.year}</div>
                        <div className="green-circle">{card.last === true && <img className="green-line" src={width > 576 ? '/image/company_milestones_line.svg' : '/image/company_milestones_line_mobile.svg'} alt="green-line" />}</div>
                        <div className="text-container">{t(card.text)}</div>
                    </div>
                </div>
            )
        })
        return cards
    }

    const getManagement = () => {
        const cards: React.ReactElement[] = []

        CompanyManagement.map((card, index) => {
            cards.push(<ManagementCard key={index} url={card.imageUrl} title={card.name} description={card.description} setModalVisible={handleModalVisible} id={1 + index} />)
        })
        return cards
    }

    return (
        <main className="page page-company">
            <div className={`main-div ${state.modalVisible === true ? 'disable-scroll' : ''}`}>
                <div className="description-container">
                    <div className="description-text">
                        <div dangerouslySetInnerHTML={{ __html: t('company_description_text_first') }} />
                        <div>{t('company_description_text_second_paragraph')}</div>
                        <div>{t('company_description_text_third_paragraph')}</div>
                        <div className="small-print">
                            <div dangerouslySetInnerHTML={{ __html: t('company_description_small_print_first') }} />
                            <div dangerouslySetInnerHTML={{ __html: t('company_description_small_print_second') }} />
                        </div>
                    </div>
                </div>
                <div className="content-card">
                    <div className="title-container">
                        <div className="title-text-h1 title">{t('company_shaded_content_title')}</div>
                        <div className="title-text-h2 subtitle">{t('company_shaded_content_first_subtitle')}</div>
                    </div>
                    <div className="reference-container">{width <= 576 ? <ViewPager cards={getReferenceCards()} withPadding={true} /> : <>{getReferenceCards().map((card, index) => card)}</>}</div>
                    <div className="title-container">
                        <div className="title-text-h2 subtitle">{t('company_milestones_title')}</div>
                    </div>
                    <div className="milestone-space-container">{getMilestones().map((card, index) => card)}</div>
                </div>
                <div className="management-container">
                    <div className="management-title-container">
                        <div className="title-text-h1">{t('company_management_title')}</div>
                    </div>
                    <div className="management-card-container">{width <= 1120 ? <ViewPager cards={getManagement()} /> : <>{getManagement().map((card, index) => card)}</>}</div>
                </div>
                <div className="afterword-container">
                    <div className="afterword">{t('company_afterword')}</div>

                    <Link to={'https://www.otpbank.hu/otpalapkezelo/hu/fooldal'} target="_blank" rel="noopener noreferrer">
                        <button className="button afterword-button">{t('home_bottom_section_left_title')}</button>
                    </Link>
                </div>
                <Infomation />

                <FooterCard />
            </div>
            {state.modalVisible && width > 576 ? (
                <ManagementModal url={CompanyManagement[state.managerId - 1].imageUrl} title={t(CompanyManagement[state.managerId - 1].name)} description={t(CompanyManagement[state.managerId - 1].description)} postDescription={t(CompanyManagement[state.managerId - 1].postDesctription)} onCloseClick={() => handleModalVisible(NaN)} />
            ) : (
                state.modalVisible && width <= 576 && <MobileModal visible={state.modalVisible} title={t(CompanyManagement[state.managerId - 1].name)} description={t(CompanyManagement[state.managerId - 1].description)} postDescription={t(CompanyManagement[state.managerId - 1].postDesctription)} hideButton={true} onCloseClick={() => handleModalVisible(NaN)} />
            )}
        </main>
    )
}

import { useEffect, useRef } from 'react'
import { useAppState } from '../../hook'
import './view-pager.scss'

interface ViewPagerProps {
    cards: React.ReactElement[]
    withPadding?: Boolean
}

export const ViewPager = (props: ViewPagerProps) => {
    const cardsRef = useRef<HTMLDivElement>(null)

    const [state, setState] = useAppState({
        currentIndex: 0,
    })

    const onScroll = () => {
        if (cardsRef.current) {
            const scrollLeft = cardsRef.current.scrollLeft
            const scrollWidth = cardsRef.current.scrollWidth
            const clientWidth = cardsRef.current.clientWidth

            var scrollPercentage = (100 * scrollLeft) / (scrollWidth - clientWidth)
            //onsole.log('scrollPercentage', scrollPercentage)

            var onItemPercent = 100 / props.cards.length

            var index = Math.floor((scrollPercentage + onItemPercent) / onItemPercent)

            //console.log('onItemPercent', onItemPercent)
            //console.log('Math.floor', Math.floor(scrollPercentage / onItemPercent))

            if (index > props.cards.length) {
                index = props.cards.length
            }

            //console.log('scrollLeft', scrollLeft)
            //console.log('scrollWidth', scrollWidth)
            //console.log('clientWidth', clientWidth)
            //console.log('index', index)

            setState((prevState) => ({ ...prevState, currentIndex: index - 1 }))
        }
    }

    const onDotClick = (position: number) => {
        if (cardsRef.current) {
            cardsRef.current.removeEventListener('scroll', onScroll)

            cardsRef.current.scrollTo(cardsRef.current.clientWidth * position, 0)
            setState((prevState) => ({ ...prevState, currentIndex: position }))

            cardsRef.current.addEventListener('scroll', onScroll)
        }
    }

    useEffect(() => {
        if (cardsRef.current) {
            cardsRef.current.addEventListener('scroll', onScroll)
        }

        return () => {
            if (cardsRef.current) {
                cardsRef.current.removeEventListener('scroll', onScroll)
            }
        }
    }, [])

    return (
        <div className="viewpager">
            <div className="cards" ref={cardsRef}>
                {props.cards.map((card, index) => (
                    <div className={`card-item ${props.withPadding != null && props.withPadding != undefined && props.withPadding === false ? `` : `padding`}`}>{card}</div>
                ))}
            </div>

            <div className="indicator">
                {props.cards.map((card, index) => (
                    <div className={`dot ${state.currentIndex === index ? 'active' : ''}`} onClick={() => onDotClick(index)}></div>
                ))}
            </div>
        </div>
    )
}

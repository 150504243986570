import { LoaderFunctionArgs } from 'react-router-dom'
import { getFund } from '../action/fund'
import { getDocuments } from '../action/document'
import i18n from 'i18next'

export const getFundLoader = async (args: LoaderFunctionArgs) => {
    if (args.request.url.includes('/konzervativ')) {
        await getFund(1)
        await getDocuments(1, i18n.language === undefined ? 'hu' : i18n.language.includes('bg') ? 'bg' : i18n.language.includes('hu') ? 'hu' : i18n.language.includes('hr') ? 'hr' : i18n.language.includes('mne') ? 'mne' : i18n.language.includes('sl') ? 'sl' : 'en')
    }

    if (args.request.url.includes('/kiegyensulyozott')) {
        await getFund(2)
        await getDocuments(2, i18n.language === undefined ? 'hu' : i18n.language.includes('bg') ? 'bg' : i18n.language.includes('hu') ? 'hu' : i18n.language.includes('hr') ? 'hr' : i18n.language.includes('mne') ? 'mne' : i18n.language.includes('sl') ? 'sl' : 'en')
    }

    if (args.request.url.includes('/dinamikus')) {
        await getFund(3)
        await getDocuments(3, i18n.language === undefined ? 'hu' : i18n.language.includes('bg') ? 'bg' : i18n.language.includes('hu') ? 'hu' : i18n.language.includes('hr') ? 'hr' : i18n.language.includes('mne') ? 'mne' : i18n.language.includes('sl') ? 'sl' : 'en')
    }

    return null
}

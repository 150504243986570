import { validate } from 'class-validator'
import {  GetExchangeRatesDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { plainToClass } from 'class-transformer'
import { GET_EXCHANGE_RATE_ACTIONS } from './exchange-rate.constants'

export const getExchangeRate = async () => {

    store.dispatch({ type: GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_STARTED })

    const promise = createCall('microsite/exchange-rate', BASE_METHODS.GET)
    promise.then(async response => {

        let responseBody: GetExchangeRatesDTO

        try {
            responseBody = plainToClass( GetExchangeRatesDTO, JSON.parse( await response.text() ) )

        } catch (error) {
            //console.log(error)
            store.dispatch({ type: GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_INVALID_JSON })
            return
        }

        if ( ( await validate( responseBody ) ).length > 0 ) {
            store.dispatch(store.dispatch({ type: GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_INVALID_RESPONSE }))
            return
        }

        //console.log(responseBody)

        store.dispatch({ type: GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_OK, exchangeRates: responseBody.exchangeRates})
       
    })

    promise.catch(error => {
        console.log(error)
        store.dispatch({ type: GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_ERROR })
    })
}



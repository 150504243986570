import { Link } from 'react-router-dom'
import { useAppTranslation } from '../../hook'
import './terms.page.scss'

export const Terms = () => {
    const { t, i18n } = useAppTranslation()
    return (
        <main className="page page-terms">
            <div className="main-div">
                <div className="content-card">
                    <div className="text-container">
                        <div className="content-simple-text">
                            <div>{t('terms_page_content_first_text')}</div>
                            <div>{t('terms_page_content_second_text')}</div>
                            <div className="title-text-h2 content-title">{t('terms_page_content_first_tile')}</div>
                            <div>{t('terms_page_content_third_text')}</div>
                            <div className="title-text-h2 content-title">{t('terms_page_content_second_tile')}</div>
                            <div>{t('terms_page_content_fourth_text')}</div>
                            <div className="title-text-h2 content-title">{t('terms_page_content_third_tile')}</div>
                            <div>{t('terms_page_content_fifth_text')}</div>
                            <Link className="form-terms-label" target="_blank" rel="noopener noreferrer" to={i18n.language.includes('hu') ? 'https://www.otpbank.hu/static/otpalapkezelo/download/Adatkezelesi_tajekoztato.pdf' : 'https://www.otpbank.hu/static/otpalapkezelo/download/Data_protection_guide.pdf'}>
                                <div className="form-terms-label" dangerouslySetInnerHTML={{ __html: t('terms_page_content_last_text') }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

import { useAppTranslation } from '../../../../hook'
import './management-card.scss'

type ManagementCardProps = {
    key: number
    url: string
    title: string
    description: string
    setModalVisible: (id: number) => void
    id: number
}

export const ManagementCard = ({ key, url, title, description, setModalVisible, id }: ManagementCardProps) => {
    const { t, i18n } = useAppTranslation()
    return (
        <div key={key} className="management-card">
            <div className="image-container">
                <div className="image" style={{ backgroundImage: `url(${url})` }} />
            </div>

            <div className={`title-container ${i18n.language.includes('bg') ? `bulgarian` : ``}`}>
                <div className="management-title">{t(title)}</div>
                <div className={`management-description ${i18n.language.includes('bg') ? `bulgarian` : ``}`}>{t(description)}</div>
            </div>
            <div className={`button-container ${i18n.language.includes('bg') ? `bulgarian` : ``}`}>
                <button className="button" onClick={() => setModalVisible(id)}>
                    {t('management_card_introduction_button_title')}
                </button>
            </div>
        </div>
    )
}

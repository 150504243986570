import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from '../store'

export const useAppTranslation = () => useTranslation()
export const useAppNavigate = () => useNavigate()
export const useAppState = <T>(initialState: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    return useState<T>(initialState)
}
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

import { validate } from 'class-validator'
import { GetDocumentsDTO } from '../../dto'
import { store } from '../../store'
import { createCall } from '../base'
import { BASE_METHODS } from '../base/base.constants'
import { plainToClass } from 'class-transformer'
import { GET_DOCUMENTS_ACTIONS } from './get-documents.constants'

export const getDocuments = async (type: number, language: string) => {
    store.dispatch({ type: GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_STARTED })

    const promise = createCall(`microsite/documents/?type=${type}&language=${language}`, BASE_METHODS.GET)
    promise.then(async (response) => {
        let responseBody: GetDocumentsDTO

        try {
            responseBody = plainToClass(GetDocumentsDTO, JSON.parse(await response.text()))
        } catch (error) {
            //console.log(error)
            store.dispatch({ type: GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_INVALID_JSON })
            return
        }

        if ((await validate(responseBody)).length > 0) {
            store.dispatch(store.dispatch({ type: GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_INVALID_RESPONSE }))
            return
        }

        store.dispatch({ type: GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_OK, documents: responseBody.documents })
    })

    promise.catch((error) => {
        console.log(error)
        store.dispatch({ type: GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_ERROR })
    })
}

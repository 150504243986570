import { Link } from 'react-router-dom'
import { useAppState, useAppTranslation, useWindowDimensions } from '../../hook'
import './cookies.page.scss'

export const Cookies = () => {
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()
    const [state, setState] = useAppState({
        index: 0,
    })

    const onClickBack = () => {
        if (state.index !== 0) {
            setState((prevState) => ({ ...prevState, index: state.index - 1 }))
        }
    }

    const onClickFurther = () => {
        if (state.index !== 3) {
            setState((prevState) => ({ ...prevState, index: state.index + 1 }))
        }
    }

    return (
        <main className="page page-cookies">
            <div className="main-div">
                <div className="content-card">
                    <div className="content-container">
                        <div className="content-simple-text">{t('cookies_page_content_explain')}</div>
                        <div className="title-text-h2 content-title">{t('cookies_page_content_what_are_cookies')}</div>
                        <div className="content-simple-text">{t('cookies_page_content_explain_what_cookies_are')}</div>
                        <div className="title-text-h2 content-title">{t('cookies_page_content_what_kind_of_cookies_do_we_use')}</div>
                        <div className="content-simple-text">{t('cookies_page_content_this_is_why_we_use_cookies')}</div>
                        <ul>
                            <li key={0}>{t('cookies_page_content_develop_website')}</li>
                            <li key={1}>{t('cookies_page_content_navigation_handling')}</li>
                            <li key={2}>{t('cookies_page_content_collect_information')}</li>
                        </ul>
                        <div className="title-text-h2 content-title">{t('cookies_page_content_basic_usable_cookies')}</div>
                        <div className="content-simple-text">{t('cookies_page_content_explain_what_basic_usable_cookies_doing')}</div>
                        <div className="title-text-h2 content-title">{t('cookies_page_content_privacy_policy_title')}</div>
                        <div className="content-simple-text">
                            <div>{t('cookies_page_content_privacy_policy_description')}</div>
                            <div>{t('cookies_page_content_basic_cookoes_we_use')}</div>
                        </div>
                        <div className="table-container">
                            {(state.index === 0 || width > 576) && (
                                <div className="table-content">
                                    <div className="title-text-h3 table-content-header first">
                                        {width <= 576 && (
                                            <div onClick={onClickBack} className="pager-button inactive">
                                                <div className="inner-picture-back inactive-picture" />
                                            </div>
                                        )}
                                        {t('cookies_page_content_table_first_header')}
                                        {width <= 576 && (
                                            <div onClick={onClickFurther} className="pager-button active">
                                                <div className="inner-picture-further" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="title-text-h3 table-content-body first">{t('cookies_page_content_table_first_description')}</div>
                                    <div className="table-content-footer first"></div>
                                </div>
                            )}
                            {(state.index === 1 || width > 576) && (
                                <div className="table-content">
                                    <div className="title-text-h3">
                                        <div className="table-content-header">
                                            {width <= 576 && (
                                                <div onClick={onClickBack} className="pager-button active">
                                                    <div className="inner-picture-back active-picture" />
                                                </div>
                                            )}
                                            {t('cookies_page_content_table_second_header')}
                                            {width <= 576 && (
                                                <div onClick={onClickFurther} className="pager-button active">
                                                    <div className="inner-picture-further" />
                                                </div>
                                            )}
                                        </div>

                                        <div className="table-content-body">{t('cookies_page_content_table_second_description')}</div>
                                        <div className="table-content-footer default"></div>
                                    </div>
                                </div>
                            )}
                            {(state.index === 2 || width > 576) && (
                                <div className="table-content">
                                    <div className="title-text-h3">
                                        <div className="table-content-header">
                                            {width <= 576 && (
                                                <div onClick={onClickBack} className="pager-button active">
                                                    <div className="inner-picture-back active-picture" />
                                                </div>
                                            )}
                                            {t('cookies_page_content_table_third_header')}
                                            {width <= 576 && (
                                                <div onClick={onClickFurther} className="pager-button active">
                                                    <div className="inner-picture-further" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="table-content-body">
                                            <div>
                                                <span className="title-text-h3 multitext-header">{t('cookies_page_content_table_third_description_header')}</span>
                                                <span>{t('cookies_page_content_table_third_description_description')}</span>
                                            </div>
                                        </div>
                                        <div className="table-content-footer default">
                                            <div className="footer-description multitext">
                                                <div className="footer-wrapper">
                                                    <span className="title-text-h3 multitext-header">{t('cookies_page_content_table_third_footer_header')}</span>
                                                    {width <= 576 && <>&nbsp;</>}
                                                    <span>{t('cookies_page_content_table_third_footer_description')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(state.index === 3 || width > 576) && (
                                <div className="table-content">
                                    <div className="title-text-h3">
                                        <div className="table-content-header last">
                                            {width <= 576 && (
                                                <div onClick={onClickBack} className="pager-button active">
                                                    <div className="inner-picture-back active-picture" />
                                                </div>
                                            )}
                                            {t('cookies_page_content_table_fourth_header')}
                                            {width <= 576 && (
                                                <div onClick={onClickFurther} className="pager-button inactive">
                                                    <div className="inner-picture-further inactive-picture" />
                                                </div>
                                            )}
                                        </div>
                                        <div className="table-content-body last">{t('cookies_page_content_table_fourth_description')}</div>
                                        <div className="table-content-footer last">
                                            <div className="title-text-h3 footer-description">{t('cookies_page_content_table_fourth_footer')}</div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="content-simple-text">
                            <div>{t('cookies_page_content_footer_fisrt_description')}</div>
                            <div>{t('cookies_page_content_footer_second_description')}</div>
                            <div>
                                {t('cookies_page_content_footer_third_description_first_part_before_link')}
                                <Link className="content-link" target="_blank" rel="noopener noreferrer" to={'http://naih.hu/'}>
                                    http://naih.hu/
                                </Link>
                                {t('cookies_page_content_footer_third_description_second_part_between_links')}
                                <a className="content-link" href="mailto:ugyfelszolgalat@naih.hu">
                                    ugyfelszolgalat@naih.hu
                                </a>
                                {t('cookies_page_content_footer_third_description_last_part')}
                            </div>
                            <div>
                                {t('cookies_page_content_footer_fourth_description_first_part')}
                                <Link className="content-link" target="_blank" rel="noopener noreferrer" to={'http://birosag.hu/torvenyszekek'}>
                                    http://birosag.hu/torvenyszekek
                                </Link>
                                {t('cookies_page_content_footer_fourth_description_second_part')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

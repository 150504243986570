import { BaseState } from '../state'

const initialBaseState: BaseState = {
    menu: 0,
    // lang: 'en',
}

export const baseReducer = (state: BaseState = initialBaseState, action: any): BaseState => {
    switch (action.type) {
        case 'BASE_CHANGE_MENU':
            return { ...state, menu: action.id  } 
        // case 'BASE_CHANGE_LANGUAGE':
        //     return { ...state, lang: action.lang  } 
        default:
            return state
    }
}

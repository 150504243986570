import { GET_DOCUMENTS_ACTIONS } from '../action/document/get-documents.constants'
import { DocumentState } from '../state'

const initialDocumentState: DocumentState = {
    documents: null,
    aletMessage: '',
}

export const documentReducer = (state: DocumentState = initialDocumentState, action: any): DocumentState => {
    switch (action.type) {
        case GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_STARTED:
            return { ...state }
        case GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_INVALID_JSON:
            return { ...state }
        case GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_INVALID_RESPONSE:
            return { ...state, aletMessage: `Váratlan hiba történt!\nA hiba tényét rögzítettük és továbbítottuk a fejlesztőknek!` }
        case GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_OK:
            return { ...state, documents: action.documents }
        case GET_DOCUMENTS_ACTIONS.GET_DOCUMENTS_ERROR:
            return { ...state, aletMessage: action.message }
        default:
            return state
    }
}

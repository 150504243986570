import { Link } from 'react-router-dom'
import './profile.card.scss'

interface ProfileCardProps {
    id: number
    title: string
    name: string
    position: string
    buttonTitle: string
    setModalVisible: (id: number) => void
}

export const ProfileCard = (props: ProfileCardProps) => {
    return (
        <div className="profile-card">
            <h2 className="title-text-h1">{props.title}</h2>
            <div className={`halas-profile-image${props.name.includes('Czakó') ? ` czako-profile-image` : props.name.includes('Gajdács') ? ` gajdacs-profile-image` : props.name.includes('Heinz') ? ` heinz-profile-image` : ``}`} />
            <h2 className="title-text-h2">{props.name}</h2>
            <h3 className="title-text-h3">{props.position}</h3>
            <button className="button" onClick={() => props.setModalVisible(props.id)}>
                {props.buttonTitle}
            </button>
        </div>
    )
}

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FloatingExchangeMenu } from './floating-exchange-menu'
import { useState } from 'react'
import { ExchangeModal } from './exchange-modal'
import { Found, Menu } from '../../../enum'
import { Dropdown } from '../../dropdown'
import './side-menu.scss'

interface HeaderSideMenuProps {
    open: boolean
    language: string
    menu: number
    onMenuItemClick: (id: number) => void
    onCloseButtonClick: () => void
    onChangeLanguageClick: (lng: string) => void
}

export const HeaderSideMenu = (props: HeaderSideMenuProps) => {
    const { t } = useTranslation()
    const [floatingExchangeMenuVisible, setFloatingExchangeMenuVisible] = useState(false)
    const [exchangeModalVisible, setExchangeModalVisible] = useState(false)
    const [exchangeModalTitle, setExchangeModalTitle] = useState('')

    const onExchangeMenuItemClick = (type: Found) => {
        switch (type) {
            case Found.Conservative:
                setExchangeModalTitle(t('founds_page_landing_page_first_card_header_title'))
                break
            case Found.Balanced:
                setExchangeModalTitle(t('founds_page_landing_page_second_card_header_title'))
                break
            case Found.Dynamic:
                setExchangeModalTitle(t('founds_page_landing_page_third_card_header_title'))
                break
            default:
                break
        }

        setFloatingExchangeMenuVisible(false)
        setExchangeModalVisible(true)
    }

    const onLanguageChange = (itemId: any) => {
        props.onChangeLanguageClick(itemId == 1 ? 'en' : itemId == 2 ? 'hu' : itemId == 3 ? 'hr' : itemId == 4 ? 'mne' : itemId == 5 ? 'sl' : 'bg')
    }

    return (
        <div className={`header-side-menu ${props.open ? `` : `hidden`}`}>
            <div className="top-bar">
                <Link to="/">
                    <img alt="OTP logo crop" className="logo" src="/image/otp_alapkezelo_green_logo.svg" />
                </Link>

                <div className="language-menu-container">
                    {/* <a className="language" onClick={() => props.onChangeLanguageClick('hu' === props.language ? 'en' : 'hu')}>
                        {`${'hu' === props.language ? t('header_langugage_en_short_button') : t('header_langugage_hu_short_button')}`}
                    </a> */}

                    <button className="menu-button" onClick={() => props.onCloseButtonClick()}>
                        <img alt="Bezárás ikon" src="/image/ic_close.svg" />
                    </button>
                </div>
            </div>

            <div className="exchange">
                <button className="exchange-button" onClick={() => setFloatingExchangeMenuVisible(!floatingExchangeMenuVisible)}>
                    {t('header_exchange_button')}
                </button>

                <div className="divider"></div>

                <button className="arrow-button" onClick={() => setFloatingExchangeMenuVisible(!floatingExchangeMenuVisible)}>
                    <img className={`arrow-icon ${floatingExchangeMenuVisible ? 'rotate' : ''}`} alt="Nyíl ikon" src="/image/ic_arrow_down.svg" />
                </button>
            </div>

            <ul className="menu-bar">
                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.Home === props.menu ? `active` : ``}`}
                        to={'/'}
                        onClick={() => {
                            props.onCloseButtonClick()
                            props.onMenuItemClick(Menu.Home)
                        }}
                    >
                        {t('header_menu_home_button')}
                    </Link>
                </li>

                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.Founds === props.menu || Menu.FoundsBalanced === props.menu || Menu.FoundsConservative === props.menu || Menu.FoundsDynamic === props.menu ? `active` : ``}`}
                        to={'/alapok'}
                        onClick={() => {
                            props.onCloseButtonClick()
                            props.onMenuItemClick(Menu.Founds)
                        }}
                    >
                        {t('header_menu_founds_button')}
                    </Link>
                </li>

                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.Managers === props.menu ? `active` : ``}`}
                        to={'/menedzserek'}
                        onClick={() => {
                            props.onCloseButtonClick()
                            props.onMenuItemClick(Menu.Managers)
                        }}
                    >
                        {t('header_menu_managers_button')}
                    </Link>
                </li>

                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.Company === props.menu ? `active` : ``}`}
                        to={'/cegunk'}
                        onClick={() => {
                            props.onCloseButtonClick()
                            props.onMenuItemClick(Menu.Company)
                        }}
                    >
                        {t('header_menu_impressum_button')}
                    </Link>
                </li>

                <li className="menu-bar-item">
                    <Link
                        className={`title ${Menu.Contacts === props.menu ? `active` : ``}`}
                        to={'/kapcsolat'}
                        onClick={() => {
                            props.onCloseButtonClick()
                            props.onMenuItemClick(Menu.Contacts)
                        }}
                    >
                        {t('header_menu_contacts_button')}
                    </Link>
                </li>
            </ul>

            <Dropdown
                value={props.language == 'en' ? 1 : props.language == 'hu' ? 2 : props.language == 'hr' ? 3 : props.language == 'mne' ? 4 : props.language == 'sl' ? 5 : 6}
                error={''}
                items={[
                    { id: 1, value: t('header_langugage_en_button') },
                    { id: 2, value: t('header_langugage_hu_button') },
                    { id: 3, value: t('header_langugage_hr_button') },
                    { id: 4, value: t('header_langugage_mne_button') },
                    { id: 5, value: t('header_langugage_sl_button') },
                    { id: 6, value: t('header_langugage_bg_button') },
                ]}
                onChange={onLanguageChange}
            />

            <FloatingExchangeMenu open={floatingExchangeMenuVisible} onItemClick={onExchangeMenuItemClick} />

            <ExchangeModal open={exchangeModalVisible} title={exchangeModalTitle} date={'2023.01.26.'} exchangeRate={'0,024987894 EUR'} netPrice={'54 651 651 EUR'} onCloseButtonClick={() => setExchangeModalVisible(false)} />
        </div>
    )
}

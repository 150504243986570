import { Link } from 'react-router-dom'
import './card.scss'
import { useAppDispatch } from '../../hook'
import { Menu } from '../../enum'
import { useTranslation } from 'react-i18next'

interface CardProps {
    key: number
    title: string
    description: string
    buttonTitle: string
    imageUrl: string
    alt: string
    type: string
    path: string
    fixedHeight?: boolean
}

export const Card = (props: CardProps) => {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }
    // <div className={`text-container ${state.needSmallMargin ? `small-margin` : ``} ${i18n.language.includes('bg') ? `bulgarian` : ``}`}>

    return (
        <div className="card" key={props.key}>
            <img className="picture" src={props.imageUrl} alt={props.alt} />

            <div className={`card-content ${props.fixedHeight ? 'open' : 'close'} ${props.type} ${i18n.language.includes('bg') ? `bg` : ``}`}>
                <h2 className="card-title">{props.title}</h2>
                <div className={`card-description ${props.description === '' ? 'hidden' : ''}  `}>{props.description}</div>
                <div className="bottom-content">
                    <Link
                        className="footer-button-link"
                        to={props.path}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0 })
                            onMenuItemClick(props.path === '/alapok/konzervativ' ? Menu.FoundsConservative : props.path === '/alapok/kiegyensulyozott' ? Menu.FoundsBalanced : Menu.FoundsDynamic)
                        }}
                    >
                        <button className={`button card-content-footer-button  ${i18n.language.includes('bg') ? `bg` : ``}`}>{props.buttonTitle}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export enum Menu {

    Home = 0,
    Founds = 1,
    Managers = 2,
    Company = 3,
    Contacts = 4,

    Cookies = 5,
    Impressum = 6,
    Terns = 7,

    FoundsConservative = 11,
    FoundsBalanced = 12,
    FoundsDynamic = 13,

}
import { useTranslation } from 'react-i18next'
import './floating-exchange-menu.scss'
import { Found } from '../../../../enum'

interface FloatingExchangeMenuProps {
    open: boolean
    onItemClick: (type: Found) => void
}

export const FloatingExchangeMenu = (props: FloatingExchangeMenuProps) => {
    const { t } = useTranslation()

    return (
        <div className={`floating-exchange-menu ${props.open ? `` : `hidden`}`}>
            <p className="floating-exchange-menu-title" onClick={() => props.onItemClick(Found.Conservative)}>
                {t('founds_page_landing_page_first_card_header_title')}
            </p>
            <div className="divider" />
            <p className="floating-exchange-menu-title" onClick={() => props.onItemClick(Found.Balanced)}>
                {t('founds_page_landing_page_second_card_header_title')}
            </p>
            <div className="divider" />
            <p className="floating-exchange-menu-title" onClick={() => props.onItemClick(Found.Dynamic)}>
                {t('founds_page_landing_page_third_card_header_title')}
            </p>
        </div>
    )
}

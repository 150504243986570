import { Expose, Type } from 'class-transformer'
import { IsDefined, IsNumber, IsString, ValidateIf, ValidateNested } from 'class-validator'
import { MediaFile } from './media-file-entity'

export class Document {
    @Expose()
    @IsDefined()
    @IsNumber()
    id!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsString()
    language!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    type!: number

    @Expose()
    @ValidateNested()
    @Type((type) => MediaFile)
    @ValidateIf((_, value) => value !== null)
    file!: MediaFile | null

    @Expose()
    @IsDefined()
    @IsNumber()
    created!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    updated!: number
}

import { GET_EXCHANGE_RATE_ACTIONS } from '../action/exchange-rate/exchange-rate.constants'
import { ExchangeRateState } from '../state'

const initialExchangeRateState: ExchangeRateState = {
    exchangeRates: null, 
    aletMessage: '' 
}

export const exchangeRateReducer = (state: ExchangeRateState = initialExchangeRateState, action: any): ExchangeRateState => {
    switch (action.type) {
        case GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_STARTED:
            return { ...state }
        case GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_INVALID_JSON:
            return { ...state }
        case GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_INVALID_RESPONSE:
            return { ...state, aletMessage: `Váratlan hiba történt!\nA hiba tényét rögzítettük és továbbítottuk a fejlesztőknek!` }
        case GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_OK:
            return { ...state, exchangeRates: action.exchangeRates }
        case GET_EXCHANGE_RATE_ACTIONS.GET_EXCHANGE_RATE_ERROR:
            return { ...state, aletMessage: action.message }
        default:
            return state
    }
}

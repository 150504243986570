import { Expose } from 'class-transformer'
import { IsDefined, IsNumber, IsString } from 'class-validator'

export class ExchangeRate {
    
    @Expose()
    @IsDefined()
    @IsNumber()
    fundId!: number

    @Expose()
    @IsDefined()
    @IsString()
    title!: string

    @Expose()
    @IsDefined()
    @IsNumber()
    date!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    exchangeRate!: number

    @Expose()
    @IsDefined()
    @IsNumber()
    netPrice!: number
}



import { useTranslation } from 'react-i18next'
import './exchange-modal.scss'

interface ExchangeModalProps {
    open: boolean
    title: string
    date: string
    exchangeRate: string
    netPrice: string
    onCloseButtonClick: () => void
}

export const ExchangeModal = (props: ExchangeModalProps) => {
    const { t } = useTranslation()

    return (
        <div className={`exchange-modal ${props.open ? `` : `hidden`}`}>
            <div className="exchange-modal-top-bar">
                <h1 className="exchange-modal-title">{props.title}</h1>

                <button className="close-button" onClick={() => props.onCloseButtonClick()}>
                    <img alt="Bezárás ikon" src="/image/ic_close_modal.svg" />
                </button>
            </div>

            <div className="exchange-modal-bottom">
                <div className="exchange-modal-container">
                    <p className="exchange-modal-sub-title">{t('header_founds_date_title')}</p>
                    <p className="exchange-modal-sub-title">{props.date}</p>
                </div>

                <div className="exchange-modal-container" style={{ backgroundColor: 'white' }}>
                    <p className="exchange-modal-sub-title">{t('header_founds_exchange_rate_title')}</p>
                    <p className="exchange-modal-sub-title">{props.exchangeRate}</p>
                </div>

                <div className="exchange-modal-container">
                    <p className="exchange-modal-sub-title">{t('header_founds_net_price_title')}</p>
                    <p className="exchange-modal-sub-title">{props.netPrice}</p>
                </div>
            </div>
        </div>
    )
}

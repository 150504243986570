import { useState } from 'react'
import './tab-view.scss'

interface TabViewProps {
    children: JSX.Element | JSX.Element[]
    tabs: { [key: string]: string }
}

export const TabView = (props: TabViewProps) => {
    const [activeKey, setActiveKey] = useState(Object.keys(props.tabs)[0])
    const children = Array.isArray(props.children) ? props.children : [props.children]

    return (
        <div className="tab-view">
            <header>
                {Object.keys(props.tabs).map((key: string) => (
                    <div className={`tab${activeKey === key ? ` tab--active` : ``}`} key={key} onClick={() => setActiveKey(key)}>
                        <div className="before" style={{ display: key === activeKey && Object.keys(props.tabs)[0] !== activeKey ? ` ` : ` none` }}>
                            <div className="inner" />
                        </div>
                        {props.tabs[key]}
                        <div className="after" style={{ display: key === activeKey ? ` ` : ` none` }}>
                            <div className="inner" />
                        </div>
                    </div>
                ))}
            </header>

            <div className={`tab-view-pages${Object.keys(props.tabs).indexOf(activeKey) === 0 ? ` ` : ` rounded`}`}>{children.filter((child: JSX.Element) => child.key === activeKey)}</div>
        </div>
    )
}

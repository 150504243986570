export const cards = [
    {
        title: 'founds_page_landing_page_first_card_header_beaks_title',
        description: 'founds_page_landing_page_first_card_content',
        buttonTitle: 'founds_page_landing_page_card_button_title',
        imageUrl: '/image/otp_multi_asset_konzervativ_alapok_alapja_logo.png',
        alt: 'otp_multi_asset_konzervativ_alapok',
        type: 'first',
        path: '/alapok/konzervativ',
        fixedHeight: true,
    },
    {
        title: 'founds_page_landing_page_second_card_header_beaks_title',
        description: 'founds_page_landing_page_second_card_content',
        buttonTitle: 'founds_page_landing_page_card_button_title',
        imageUrl: '/image/otp_multi_asset_kiegyensulyozott_alapok_alapja_logo.png',
        alt: 'otp_multi_asset_kiegyensulyozott_alapok_alapja',
        type: 'second',
        path: '/alapok/kiegyensulyozott',
        fixedHeight: true,
    },
    {
        title: 'founds_page_landing_page_third_card_header_beaks_title',
        description: 'founds_page_landing_page_third_card_content',
        buttonTitle: 'founds_page_landing_page_card_button_title',
        imageUrl: '/image/otp_multi_asset_dinamius_alapok_alapja_logo.png',
        alt: 'otp_multi_asset_dinamius_alapok_alapja',
        type: 'third',
        path: '/alapok/dinamikus',
        fixedHeight: true,
    },
]
import { Expose, Type } from 'class-transformer'
import { ValidateNested } from 'class-validator'
import { Message } from '../entity'

export class CreateMessageDTO {
    
    @Expose()
    @ValidateNested()
    @Type(() => Message)
    message!: Message[]
}

export const createFormattedDateString = (timestamp: number): string => {
    const date = new Date(timestamp)

    const dateString = date.getFullYear() + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getDate().toString().padStart(2, '0') + '.'

    return dateString
}

export function numberWithSpaces(x: number) {
    return x
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

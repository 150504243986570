import { Card, FooterCard, Infomation, ViewPager } from '../../component'
import { LayingCard } from './laying-card'
import { useAppTranslation, useWindowDimensions } from '../../hook'
import { cards, layingCards } from './constant'
import './founds.landing.page.scss'

export const FoundsLandingPage = () => {
    const { t } = useAppTranslation()
    const { width } = useWindowDimensions()

    const getLayingCards = () => {
        var cards: React.ReactElement[] = []

        layingCards.map((card, index) => cards.push(<LayingCard key={index} imageUrl={width <= 576 ? card.mobileImageUrl : card.imageUrl} alt={card.alt} listElems={card.listElems.map((e) => t(e))} />))
        return cards
    }

    const getStatisticCards = () => {
        const cards: React.ReactElement[] = []
        cards.push(
            <div className="shader-container">
                <div className="content-container">
                    <h1 className="shader-container-title">{t('founds_page_landing_page_second_shaded_content_title')}</h1>
                    <div className="text">{t('founds_page_landing_page_second_shaded_content_first_description')}</div>
                </div>
            </div>
        )
        cards.push(
            <div className="shader-container second">
                <div className="content-container left-side-picture">
                    <div className="right-text-container">
                        <h2 className={`shader-container-title ${width > 576 ? 'subtitle' : ''} `}>{t('founds_page_landing_page_second_shaded_content_second_description_title')}</h2>
                        <div className="text">{t('founds_page_landing_page_second_shaded_content_second_description')}</div>
                    </div>
                    <div className="left-picture-container">
                        <div className="left-side-pic-title first">
                            <span>{t('founds_page_landing_page_second_shaded_content_first_left_side_pic_title')}</span>
                        </div>
                        <div className="left-side-pic-title second">
                            <span>{t('founds_page_landing_page_second_shaded_content_second_left_side_pic_title')}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
        cards.push(
            <div className="shader-container second">
                <div className="content-container right-side-picture">
                    <div className="left-text-container">
                        <h2 className={`shader-container-title ${width > 576 ? 'subtitle' : ''} `}>{t('founds_page_landing_page_second_shaded_content_third_description_title')}</h2>
                        <div className="text">{t('founds_page_landing_page_second_shaded_content_third_description')}</div>
                    </div>
                    <div className="right-picture-container">
                        <div className="fisrt-picture-container">
                            <div className="picture-div first">
                                <div className="pic-title">{t('founds_page_landing_page_second_shaded_content_fisrt_right_side_pic_title')}</div>
                            </div>
                            <div className="picture-other-div first">
                                <div className="pic-title left-padding">{t('founds_page_landing_page_second_shaded_content_second_right_side_pic_title')}</div>
                            </div>
                        </div>
                        <div className="fisrt-picture-container">
                            <div className="picture-div second">
                                <div className="pic-title">{t('founds_page_landing_page_second_shaded_content_fisrt_right_side_pic_title')}</div>
                            </div>
                            <div className="picture-other-div second">
                                <div className="pic-title">{t('founds_page_landing_page_second_shaded_content_second_right_side_pic_title')}</div>
                            </div>
                        </div>
                        <div className="fisrt-picture-container">
                            <div className="picture-div third">
                                <div className="pic-title">{t('founds_page_landing_page_second_shaded_content_fisrt_right_side_pic_title')}</div>
                            </div>
                            <div className="picture-other-div third">
                                <div className="pic-title">{t('founds_page_landing_page_second_shaded_content_second_right_side_pic_title')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        return cards
    }

    return (
        <main className="page page-founds">
            <div className="content-card">
                <div className="card-container">
                    {width <= 576 ? (
                        <FooterCard />
                    ) : (
                        <>
                            {cards.map((card, index) => (
                                <Card key={index} title={t(card.title)} description={t(card.description)} buttonTitle={t(card.buttonTitle)} imageUrl={card.imageUrl} alt={card.alt} type={card.type} fixedHeight={card.fixedHeight} path={card.path} />
                            ))}
                        </>
                    )}
                </div>
                <div className="shaded-content">
                    <h1 className="shaded-content-title">{t('founds_page_landing_page_first_shaded_content_title')}</h1>

                    <div className="shaded-content-laying-cards-mobile">{width <= 576 ? <ViewPager cards={getLayingCards()} withPadding={true} /> : <>{getLayingCards().map((card, index) => card)}</>}</div>
                </div>

                <div className="shaded-content white">
                    <div className="shaded-content-laying-cards-mobile">{width <= 576 ? <ViewPager cards={getStatisticCards()} withPadding={true} /> : <>{getStatisticCards().map((card, index) => card)}</>}</div>
                </div>
            </div>
            <div className="afterword">
                <h3>{t('founds_page_landing_page_afterwords')}</h3>
            </div>

            {width <= 576 ? (
                <>
                    <FooterCard />
                    <Infomation />
                </>
            ) : (
                <>
                    <Infomation />
                    <FooterCard />
                </>
            )}
        </main>
    )
}

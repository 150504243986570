import { ChangeEvent, createRef } from 'react'
import { Dropdown, FooterCard, Infomation, TextArea, TextField } from '../../component'
import { useAppDispatch, useAppSelector, useAppTranslation, useWindowDimensions } from '../../hook'
import { Link } from 'react-router-dom'
import { RootState } from '../../store'
import { sendMessage } from '../../action/message'
import { CREATE_MESSAGE_ACTIONS } from '../../action/message/message.constants'
import { MessageModal } from '../../component/modal'
import './contacts.page.scss'

export const Contacts = () => {
    const { t, i18n } = useAppTranslation()
    const dispatch = useAppDispatch()
    const formElement = createRef<HTMLFormElement>()
    const form = useAppSelector((state: RootState) => state.contact.form)
    const aletMessage = useAppSelector((state: RootState) => state.contact.aletMessage)
    const message = useAppSelector((state: RootState) => state.contact.message)
    const { width } = useWindowDimensions()

    const onStatusChange = (itemId: any) => {
        dispatch({
            type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_TYPE,
            typeId: itemId,
        })
    }

    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_NAME,
            name: event.target.value,
        })
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_EMAIL,
            email: event.target.value,
        })
    }

    const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_PHONE,
            phone: event.target.value,
        })
    }

    const onMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        dispatch({
            type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_MESSAGE,
            message: event.target.value,
        })
    }

    const onSendButtonClick = async () => {
        if (!checkFields()) {
            return
        }

        // if (!formElement.current?.reportValidity()) {
        //     return
        // }

        await sendMessage()
    }

    const checkFields = () => {
        if (form.type.value === 0) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_TYPE_ERROR,
                error: t('contacts_error_type_empty'),
            })

            return false
        }

        if (form.name.value.trim().length === 0) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_NAME_ERROR,
                error: t('contacts_error_name_empty'),
            })
            return false
        }

        if (form.email.value.trim().length === 0) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_EMAIL_ERROR,
                error: t('contacts_error_email_empty'),
            })
            return false
        }

        if (!isValidEmail(form.email.value.trim())) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_EMAIL_ERROR,
                error: t('contacts_error_email_format'),
            })
            return false
        }

        if (form.phone.value.trim().length === 0) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_PHONE_ERROR,
                error: t('contacts_error_phone_empty'),
            })
            return false
        }

        if (!isValidPhone(form.phone.value.trim())) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_PHONE_ERROR,
                error: t('contacts_error_phone_format'),
            })
            return false
        }

        if (form.message.value.trim().length === 0) {
            dispatch({
                type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_CHANGE_MESSAGE_ERROR,
                error: t('contacts_error_message_empty'),
            })
            return false
        }
        return true
    }

    const isValidEmail = (email: string) => {
        return /\S+@\S+\.\S+/.test(email)
    }

    const isValidPhone = (phone: string) => {
        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i
        return !(!phone || regex.test(phone) === false)
    }

    return (
        <main className="page page-contacts">
            <div className="main-div">
                <div className="contacts-container">
                    <div className={`contacts-container-bg ${window?.visualViewport!!.width < width ? `` : `full-width`}`} />

                    <div className="contacts-container-inner">
                        <div className="left-container">
                            <div className="contacts-card">
                                <div className="contacts-card-container">
                                    <h2 className="title-text-h2">{t('contacts_message_title')}</h2>

                                    <span className="label">
                                        {t('contacts_message_description_before_link')}
                                        &nbsp;
                                        <Link className="contacts-link" target="_blank" rel="noopener noreferrer" to={'https://www.otpbank.hu/portal/hu/Kapcsolat'}>
                                            {t('contacts_message_description_link')}
                                        </Link>
                                        &nbsp;{t('contacts_message_description_after_link')}
                                    </span>
                                </div>

                                <img className="icon" src="image/ic_message.svg" />
                            </div>
                            <div className="contacts-card">
                                <div className="contacts-card-container">
                                    <h2 className="title-text-h2">{t('contacts_phone_title')}</h2>

                                    <span className="label phone">{t('contacts_phone_description')}</span>
                                </div>

                                <img className="icon" src="image/otp_infomation_right_logo.svg" />
                            </div>
                        </div>

                        <div className="right-container">
                            <form
                                action=""
                                onSubmit={(e) => {
                                    e.preventDefault()
                                }}
                                className="contacts-form"
                            >
                                <Dropdown
                                    value={form.type.value}
                                    error={form.type.error}
                                    items={[
                                        { id: 1, value: t('contacts_distribution_type_title') },
                                        { id: 2, value: t('contacts_investment_type_title') },
                                    ]}
                                    onChange={onStatusChange}
                                />
                                <TextField field={form.name} maxlength={255} onChange={onNameChange} placeholder={t('contacts_name_placeholder')} />
                                <TextField field={form.email} maxlength={255} onChange={onEmailChange} placeholder={t('contacts_email_placeholder')} />
                                <TextField field={form.phone} maxlength={255} onChange={onPhoneChange} type={'tel'} placeholder={t('contacts_phone_placeholder')} />
                                <div className="form-message-container">
                                    <TextArea field={form.message} onChange={onMessageChange} placeholder={t('contacts_message_placeholder')} maxLength={300} />

                                    <div className="form-message-counter">{form.message.value.length}/300</div>
                                </div>

                                <Link className="form-terms-label" target="_blank" rel="noopener noreferrer" to={i18n.language.includes('hu') ? 'https://www.otpbank.hu/static/otpalapkezelo/download/Adatkezelesi_tajekoztato.pdf' : 'https://www.otpbank.hu/static/otpalapkezelo/download/Data_protection_guide.pdf'}>
                                    <div className="form-terms-label" dangerouslySetInnerHTML={{ __html: t('contacts_terms_title') }} />
                                </Link>

                                <button className="button" onClick={onSendButtonClick}>
                                    {t('contacts_send_button')}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <Infomation />

                <FooterCard />

                {aletMessage !== '' ? (
                    <MessageModal
                        title={t('contacts_send_error_title')}
                        message={t('contacts_send_error_message')}
                        onCloseClick={async () => {
                            dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_CLEAR })
                        }}
                    />
                ) : null}

                {message ? (
                    <MessageModal
                        title={t('contacts_send_success_title')}
                        message={t('contacts_send_success_message')}
                        onCloseClick={async () => {
                            dispatch({ type: CREATE_MESSAGE_ACTIONS.CREATE_MESSAGE_ACTION_CLEAR })
                        }}
                    />
                ) : null}
            </div>
        </main>
    )
}

import './laying.card.scss'

interface LayingCardProps {
    key: number
    imageUrl: string
    alt: string
    listElems: Array<string>
}

export const LayingCard = (props: LayingCardProps) => {
    return (
        <div className="shaded-content-laying-cards">
            <div className="laying-card-container" key={props.key}>
                <img className="laying-card-image" src={props.imageUrl} alt={props.alt} />
                <div className="laying-card-list">
                    {props.listElems.map((listElem, index) => {
                        return (
                            <ul>
                                <li key={index}>{listElem}</li>
                            </ul>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

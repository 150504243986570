import './management.modal.scss'

type ManagementModalProps = {
    url: string
    title: string
    description: string
    postDescription: string
    onCloseClick: () => void
}

export const ManagementModal = ({ url, title, description, postDescription, onCloseClick }: ManagementModalProps) => {
    return (
        <div className="custom-modal" onClick={onCloseClick}>
            <div className="modal">
                <div className="header-container">
                    <div className="header-picture" style={{ backgroundImage: `url(${url})` }} />
                    <div className="header-title-container">
                        <div className="title">{title}</div>
                        <div className="description">{description}</div>
                        <button className="modal-close-button" onClick={onCloseClick}>
                            <img src="/image/ic_close.svg" alt="/image/ic_close.svg" />
                        </button>
                    </div>
                </div>
                <div className="custom-modal-body">{postDescription}</div>
            </div>
        </div>
    )
}

import { LoaderFunctionArgs } from 'react-router-dom'
import { getExchangeRate } from '../action/exchange-rate'

export const exchangeRateLoader = async ( args: LoaderFunctionArgs ) => {

    await getExchangeRate( )

    return null

}

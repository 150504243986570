import { Expose, Type } from 'class-transformer'
import { IsNumber, ValidateNested } from 'class-validator'

export class GetFundDTO {
    
    @Expose()
    id!: number

    @Expose()
    euroSeries!: number[]

    @Expose()
    euroOptions!: number[]

    @Expose()
    ytdValue!: string

    @Expose()
    onYearValue!: string

    @Expose()
    fromStartValue!: string
}  
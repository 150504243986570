import { useAppTranslation } from '../../hook'
import './information.scss'

interface InfomationProps {
    pathPrefix?: string
}

export const Infomation = (props: InfomationProps) => {
    const { t } = useAppTranslation()

    return (
        <div className="information">
            <p className="infomation-title">{t('information_title')}</p>
            <p className="infomation-description">{t('information_description')}</p>

            <div className="info-container ">
                <div className="item">
                    <img className="icon" src={`${props.pathPrefix != undefined ? props.pathPrefix : ''}image/otp_infomation_left_logo.svg`} />

                    <a className="text" target="_blank" rel="noopener noreferrer" href={t('information_left_card_href')}>
                        {t('information_left_card_title')}
                    </a>
                </div>
                <div className="item">
                    <img className="icon" src={`${props.pathPrefix != undefined ? props.pathPrefix : ''}image/otp_infomation_center_logo.svg`} />

                    <a className="text" href={t('information_center_card_href')}>
                        {t('information_center_card_title')}
                    </a>
                </div>
                <div className="item">
                    <img className="icon" src={`${props.pathPrefix != undefined ? props.pathPrefix : ''}image/otp_infomation_right_logo.svg`} />

                    <a className="text" href={t('information_right_card_href')}>
                        {t('information_right_card_title')}
                    </a>
                </div>
            </div>
        </div>
    )
}

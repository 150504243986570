import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hook'
import { Menu } from '../../enum'
import './footer.scss'

interface FooterProps {}

export const Footer = (props: FooterProps) => {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const onMenuItemClick = (id: number) => {
        dispatch({
            type: 'BASE_CHANGE_MENU',
            id: id,
        })
    }

    return (
        <footer className="footer">
            <div className="container">
                <Link
                    to="/"
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0 })
                        onMenuItemClick(Menu.Home)
                    }}
                >
                    {i18n.language.includes('hu') ? <img alt="OTP logo crop" className="logo" src="/image/otp_alapkezelo_white_logo.svg" /> : <img alt="OTP logo crop" className="logo-en" src="/image/otp_alapkezelo_white_logo_en.svg" />}
                </Link>

                <div className="text-container">
                    <Link
                        className="title"
                        to={'/sutik'}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0 })
                            onMenuItemClick(Menu.Cookies)
                        }}
                    >
                        {t('footer_cookie_button')}
                    </Link>

                    <Link
                        className="title"
                        to={'/impresszum'}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0 })
                            onMenuItemClick(Menu.Impressum)
                        }}
                    >
                        {t('footer_impressum_button')}
                    </Link>

                    <Link
                        className="title"
                        to={'/joginyiltakozat'}
                        onClick={() => {
                            window.scrollTo({ top: 0, left: 0 })
                            onMenuItemClick(Menu.Terns)
                        }}
                    >
                        {t('footer_terms_button')}
                    </Link>

                    <div className="copyright-container">
                        <p className="copyright-title">{t('footer_copyright_title')}</p>

                        <p className="powered-by-title">{t('footer_powered_by_title')}</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

import { createBrowserRouter } from 'react-router-dom'
import { DefaultLayout } from './layout'
import { FoundsLandingPage, Managers, Home, Contacts, Company, Impressum, Terms, Cookies, FoundsDetail } from './page'
import { Menu } from './enum'
import { getFundLoader, exchangeRateLoader } from './loader'

export const router = createBrowserRouter([
    {
        children: [
            {
                element: <Home />,
                path: '',
                loader: exchangeRateLoader,
            },
            {
                element: <FoundsLandingPage />,
                path: 'alapok',
            },
            {
                element: <FoundsDetail type={Menu.FoundsConservative} />,
                path: 'alapok/konzervativ',
                loader: getFundLoader,
            },
            {
                element: <FoundsDetail type={Menu.FoundsBalanced} />,
                path: 'alapok/kiegyensulyozott',
                loader: getFundLoader,
            },
            {
                element: <FoundsDetail type={Menu.FoundsDynamic} />,
                path: 'alapok/dinamikus',
                loader: getFundLoader,
            },
            {
                element: <Managers />,
                path: 'menedzserek',
            },
            {
                element: <Company />,
                path: 'cegunk',
            },
            {
                element: <Contacts />,
                path: 'kapcsolat',
            },
            {
                element: <Cookies />,
                path: 'sutik',
            },
            {
                element: <Impressum />,
                path: 'impresszum',
            },
            {
                element: <Terms />,
                path: 'joginyiltakozat',
            },
        ],
        element: <DefaultLayout />,
        errorElement: null,
        path: '/',
    },
])

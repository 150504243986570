import { Outlet } from 'react-router-dom'
import { Footer, Header } from '../../component'
import { RootState } from '../../store'
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../hook'
import { Menu } from '../../enum'
import './default.layout.scss'
import { useEffect } from 'react'

interface DefaultLayoutProps {}

export const DefaultLayout = (props: DefaultLayoutProps) => {
    const menu = useAppSelector((state: RootState) => state.base.menu)

    const navigate = useAppNavigate()

    useEffect(() => {
        // Check if this is the first load by seeing if our object exists in local storage
        if (localStorage.getItem('firstLoadDone') === null) {
            // If it's the first load, set the flag in local storage to true and reload the page
            localStorage.setItem('firstLoadDone', '1')
            //console.log('This is the initial load')
        } else {
            //console.log('This is a page refresh')
            navigate('/')
        }
    }, [])

    return (
        <div className={`layout layout--default${Menu.Founds === menu || Menu.Cookies === menu || Menu.Impressum === menu || Menu.Terns === menu ? ` gray` : ``}`}>
            <Header />
            <Outlet />
            <Footer />
        </div>
    )
}
